const DASHBOARD = "dashboard";
const LEADERBOARD = "leaderboard";
const PLAYERS = "players";
const DECKS = "decks";
const CONFIG = "config";
const CARDS = "cards";
const AI = "ai";
const QUEST = "quest";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DASHBOARD,
  LEADERBOARD,
  PLAYERS,
  DECKS,
  CONFIG,
  CARDS,
  AI,
  QUEST,
};
