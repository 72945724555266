import React from "react";

const DashboardHeader = () => {
  return (
    <div className="content-header">
      <div className="content-title">
        Dashboard<small>Welcome Admin</small>
      </div>
    </div>
  );
};

export default DashboardHeader;
