import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Paginator from "react-hooks-paginator";
import { useDispatch, useSelector } from "react-redux";

import { getCardsInfo, getMetadata } from "../../../reducers/card.slice";
import {
  getLocations,
  getMissionList,
  removeMission,
} from "../../../reducers/quest.slice";

import AddQuestModal from "../../../components/AddQuestModal";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";

import "./missions.scss";

export default function Missions() {
  const dispatch = useDispatch();

  const { totalAIPlayerCount, missions, locations } = useSelector(
    (state) => state.quest
  );

  const [playerPerPage, setPlayerPerPage] = useState(10);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [addModalShow, setAddModalShow] = useState(false);

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [missionToRemove, setMissionToRemove] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editingMissionId, setEditingMissionId] = useState(-1);
  const [editingMission, setEditingMission] = useState({});

  const onPageClick = (i) => {
    setCurrentPage(i);
  };

  const handleAddModalShow = () => {
    setAddModalShow(true);
  };

  const handleShowEditModal = (mission) => {
    setAddModalShow(true);

    setEditingMission(mission);
    setIsEditing(true);
    setEditingMissionId(mission.id);
  };

  const handleShowConfirmModal = (aiPlayer) => {
    setMissionToRemove(aiPlayer); // Set the current AI player ID to remove
    setConfirmModalShow(true); // Show the confirmation modal
  };

  const handleRemoveMission = async () => {
    if (missionToRemove) {
      // Replace this with your actual HTTP request logic
      await dispatch(removeMission({ id: missionToRemove.id }));
      setConfirmModalShow(false); // Hide the modal after removal
      setMissionToRemove(null); // Reset the removal ID
      dispatch(getMissionList({ from: pageFrom, limit: playerPerPage }));
    }
  };

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getMissionList({ from: pageFrom, limit: playerPerPage }));
    const getCardsDetail = async () => {
      await dispatch(getMetadata());
      dispatch(getCardsInfo());
    };

    getCardsDetail();
  }, [dispatch, pageFrom, playerPerPage]);

  return (
    <>
      <Header />
      <Sidebar />
      <section className="section-container">
        <div
          className="content-wrapper"
          style={{ padding: "20px", borderTop: "0px" }}
        >
          <div className="card card-default">
            <div className="card-header d-flex">
              <div className="input-group">
                <div className="dataTables-title">AI Players</div>
              </div>
              <Button
                className="dataTable-header-button"
                onClick={handleAddModalShow}
              >
                Add
              </Button>
            </div>
            <div className="content-wrapper">
              <div className="table-responsive">
                <table
                  className="table table-bordered table-hover"
                  id="table-ext-1"
                >
                  <thead>
                    <tr className="text-center">
                      <th>ID</th>
                      <th>Location</th>
                      <th>Mission Index</th>
                      <th>Mission Title</th>
                      <th>Mission Story</th>
                      <th>AI Player name</th>
                      <th>AI Player Deck</th>
                      <th>Objective 1</th>
                      <th>Objective 2</th>
                      <th>Objective 3</th>

                      <th>Reward 1</th>
                      <th>Reward 2</th>
                      <th>Reward 3</th>

                      <th>Achievement Title</th>
                      <th>Achievement Description</th>
                      <th>Achievement Requirement</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {missions.map((mission, index) => {
                      return (
                        <tr key={index}>
                          <td>{mission.id}</td>
                          <td>
                            {
                              locations.find((location, index) => {
                                return location.id === mission.mission_location;
                              })?.location_name
                            }
                          </td>
                          <td>{mission.mission_id}</td>
                          <td>{mission.mission_title}</td>
                          <td>{mission.mission_story}</td>
                          <td>{mission.mission_ai_name}</td>
                          <td>
                            <div className="ellipsis-text">
                              {mission.mission_ai_deck}
                            </div>
                          </td>
                          <td>{mission.quest_objective_1}</td>
                          <td>{mission.quest_objective_2}</td>
                          <td>{mission.quest_objective_3}</td>

                          <td>{mission.quest_reward_1}</td>
                          <td>{mission.quest_reward_2}</td>
                          <td>{mission.quest_reward_3}</td>

                          <td>{mission.quest_achievement_title}</td>
                          <td>{mission.quest_achievement_description}</td>
                          <td>{mission.quest_achievement_requirement}</td>

                          <td>
                            <ButtonGroup className="mb-2">
                              <Button>
                                <em
                                  className="fas fa-edit"
                                  onClick={() => handleShowEditModal(mission)}
                                ></em>
                              </Button>
                              <Button
                                className="btn-danger"
                                onClick={() => handleShowConfirmModal(mission)}
                              >
                                <em className="fas fa-trash"></em>
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="card-footer">
                  <div className="d-flex">
                    <div className="ml-auto">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="datatable1_paginate"
                      >
                        <Paginator
                          totalRecords={totalAIPlayerCount}
                          pageLimit={playerPerPage}
                          pageNeighbours={2}
                          setOffset={setPageFrom}
                          currentPage={currentPage}
                          setCurrentPage={onPageClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AddQuestModal
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        addModalShow={addModalShow}
        setAddModalShow={setAddModalShow}
        editingMissionId={editingMissionId}
        pageFrom={pageFrom}
        playerPerPage={playerPerPage}
        editingMission={editingMission}
      />

      <ConfirmModal
        heading="Confirm delete AI Player"
        text={`Do you want to remove AI player ${missionToRemove?.mission_ai_name}`}
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        onConfirm={handleRemoveMission}
      ></ConfirmModal>
    </>
  );
}
