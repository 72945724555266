import { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";

import {
  addMission,
  editMission,
  getMissionList,
} from "../../reducers/quest.slice";
import ShuttleList from "../ShuttleList/ShuttleList";

const AddQuestModal = ({
  isEditing,
  setIsEditing,
  addModalShow,
  setAddModalShow,
  editingMissionId,
  pageFrom,
  playerPerPage,
  editingMission,
}) => {
  const dispatch = useDispatch();
  const { cardsInfo, cardsMetadata } = useSelector((state) => state.card);
  const { locations } = useSelector((state) => state.quest);

  const [location, setLocation] = useState(-1);
  const [mission, setMission] = useState("");
  const [missionTitle, setMissionTitle] = useState("");
  const [missionStory, setMissionStory] = useState("");
  const [aiPlayerName, setAiPlayerName] = useState("");

  // State for validation messages
  const [locationError, setLocationError] = useState("");
  const [missionError, setMissionError] = useState("");
  const [missionTitleError, setMissionTitleError] = useState("");
  const [missionStoryError, setMissionStoryError] = useState("");
  const [aiPlayerNameError, setAiPlayerNameError] = useState("");
  const [aiDeck, setAiDeck] = useState([]);

  const [availableCards, setAvailableCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [rewards, setRewards] = useState({
    reward1Star: "",
    reward2Star: "",
    reward3Star: "",
  });

  const [objectives, setObjectives] = useState({
    objective1Star: "",
    objective2Star: "",
    objective3Star: "",
  });

  const [achievements, setAchievements] = useState({
    title: "",
    description: "",
    requirement: "",
  });

  const handleAddClose = () => {
    setAddModalShow(false);
    setIsEditing(false);
  };

  // Validation functions
  const validateLevel = () => {
    if (!location || location < 0) {
      setLocationError("Level is required");
      return false;
    }
    setLocationError("");
    return true;
  };

  const validateMissionTitle = () => {
    if (!missionTitle) {
      setMissionTitleError("Mission Title is required");
      return false;
    }
    setMissionTitleError("");
    return true;
  };

  const validateMissionStory = () => {
    if (!missionStory) {
      setMissionStoryError("Mission Story is required");
      return false;
    }
    setMissionStoryError("");
    return true;
  };

  const validateMission = () => {
    if (!mission) {
      setMissionError("mission is required");
      return false;
    }
    setMissionError("");
    return true;
  };

  const validateAiPlayerName = () => {
    if (!aiPlayerName) {
      setAiPlayerNameError("AI Player Name is required");
      return false;
    }
    setAiPlayerNameError("");
    return true;
  };

  const handleAddMission = async () => {
    const isLevelValid = validateLevel();
    const isMissionValid = validateMission();
    const isMissionTitleValid = validateMissionTitle();
    const isMissionStoryValid = validateMissionStory();
    const isAiPlayerNameValid = validateAiPlayerName();

    if (
      !isLevelValid ||
      !isMissionValid ||
      !isAiPlayerNameValid ||
      !isMissionTitleValid ||
      !isMissionStoryValid
    ) {
      return; // Stop submission if validation fails
    }

    if (isEditing) {
      const newMissionInfo = {
        aiPlayerName,
        missionTitle,
        missionStory,
        rewards,
        objectives,
        achievements,
        missionId: editingMissionId,
        selectedCards: aiDeck,
      };

      await dispatch(editMission(newMissionInfo));
      dispatch(getMissionList({ from: pageFrom, limit: playerPerPage }));
      setAddModalShow(false);

      return;
    }

    const newMission = {
      location,
      mission,
      missionTitle,
      missionStory,
      aiPlayerName,
      rewards,
      objectives,
      achievements,
      selectedCards: aiDeck,
    };

    await dispatch(addMission(newMission));
    dispatch(getMissionList({ from: pageFrom, limit: playerPerPage }));
    setAddModalShow(false);
  };

  const handleRewardsChange = (e) => {
    const { name, value } = e.target;
    setRewards((prevRewards) => ({
      ...prevRewards,
      [name]: value,
    }));
  };

  const handleObjectivesChange = (e) => {
    const { name, value } = e.target;
    setObjectives((prevObjectives) => ({
      ...prevObjectives,
      [name]: value,
    }));
  };

  const handleAchievementsChange = (e) => {
    const { name, value } = e.target;
    setAchievements((prevAchievements) => ({
      ...prevAchievements,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (Object.keys(cardsInfo) == 0) return;
    const availableCardIds = [
      ...cardsInfo.guardianCards,
      ...cardsInfo.actionCards,
      ...cardsInfo.reactionCards,
    ];
    const availableCardDetails = [];
    const metadatas = Object.values(cardsMetadata);
    availableCardIds.forEach((card) => {
      const cardData = metadatas.find((md, index) => {
        return card === md.properties.id?.value;
      });
      if (cardData != null) {
        availableCardDetails.push(cardData);
      }
    });
    setAvailableCards(availableCardDetails);
  }, [cardsInfo, cardsMetadata]);

  useEffect(() => {
    if (!editingMission || Object.keys(editingMission).length === 0) return;

    setLocation(editingMission.mission_location);
    setMission(editingMission.mission_id);
    setMissionTitle(editingMission.mission_title);
    setMissionStory(editingMission.mission_story);
    setAiPlayerName(editingMission.mission_ai_name);
    setObjectives({
      objective1Star: editingMission.quest_objective_1,
      objective2Star: editingMission.quest_objective_2,
      objective3Star: editingMission.quest_objective_3,
    });

    setRewards({
      reward1Star: editingMission.quest_reward_1,
      reward2Star: editingMission.quest_reward_2,
      reward3Star: editingMission.quest_reward_3,
    });

    setAchievements({
      title: editingMission.quest_achievement_title,
      description: editingMission.quest_achievement_description,
      requirement: editingMission.quest_achievement_requirement,
    });

    const deckCards = editingMission.mission_ai_deck.split(",");
    const selectedCards = [];
    const metadatas = Object.values(cardsMetadata);
    deckCards.forEach((card) => {
      const cardId = parseInt(card);
      const cardData = metadatas.find(
        (md) => cardId === md.properties.id?.value
      );
      if (cardData != null) {
        // Check if the card is already in selectedCards
        const existingCard = selectedCards.find(
          (sc) => sc.properties.id?.value === cardId
        );
        if (existingCard) {
          // If exists, increment the count
          existingCard.count += 1;
        } else {
          // If not, add the card with a count of 1
          selectedCards.push({ ...cardData, count: 1 });
        }
      }
    });

    setSelectedCards(selectedCards);
  }, [cardsMetadata, editingMission]);

  return (
    <Modal show={addModalShow} onHide={handleAddClose}>
      <Modal.Header>
        <Modal.Title>
          {isEditing ? "Edit Quest Mission" : "Add Quest Mission"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form">
          <Form.Group controlId="formLevel">
            <Form.Label>Location</Form.Label>
            <Form.Select
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              isInvalid={!!locationError}
              disabled={isEditing}
              className="form-control"
            >
              <option value={-1}>Please Select Location</option>

              {locations.map((location, index) => (
                <option key={index} value={parseInt(location.id)}>
                  {location.location_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formMission">
            <Form.Label>Mission Level</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Mission Level"
              value={mission}
              onChange={(e) => {
                setMission(e.target.value);
                setMissionError("");
              }}
              isInvalid={!!missionError}
              disabled={isEditing}
            />
          </Form.Group>
          <Form.Group controlId="formMissionDescription">
            <Form.Label>Mission Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Mission Title"
              value={missionTitle}
              onChange={(e) => {
                setMissionTitle(e.target.value);
                setMissionTitleError("");
              }}
              isInvalid={!!missionTitleError}
            />
          </Form.Group>
          <Form.Group controlId="formMissionDescription">
            <Form.Label>Mission Story</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Mission story"
              value={missionStory}
              onChange={(e) => {
                setMissionStory(e.target.value);
                setMissionStoryError("");
              }}
              isInvalid={!!missionStoryError}
            />
          </Form.Group>
          <Form.Group controlId="formAIPlayerName">
            <Form.Label>AIPlayerName</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter AIPlayerName"
              value={aiPlayerName}
              onChange={(e) => {
                setAiPlayerName(e.target.value);
                setAiPlayerNameError("");
              }}
              isInvalid={!!aiPlayerNameError}
            />
          </Form.Group>
          <Row>
            <Form.Group as={Col} controlId="formObjectives">
              <Form.Label>Objective: 1 Star</Form.Label>
              <Form.Control
                name="objective1Star"
                value={objectives.objective1Star}
                onChange={handleObjectivesChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formObjectives">
              <Form.Label>Objective: 2 Star</Form.Label>
              <Form.Control
                name="objective2Star"
                value={objectives.objective2Star}
                onChange={handleObjectivesChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formObjectives">
              <Form.Label>Objective: 3 Star</Form.Label>
              <Form.Control
                name="objective3Star"
                value={objectives.objective3Star}
                onChange={handleObjectivesChange}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formRewards">
              <Form.Label>Rewards: 1 Star</Form.Label>
              <Form.Control
                name="reward1Star"
                value={rewards.reward1Star}
                onChange={handleRewardsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formRewards">
              <Form.Label>Rewards: 2 Star</Form.Label>
              <Form.Control
                name="reward2Star"
                value={rewards.reward2Star}
                onChange={handleRewardsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formRewards">
              <Form.Label>Rewards: 3 Star</Form.Label>
              <Form.Control
                name="reward3Star"
                value={rewards.reward3Star}
                onChange={handleRewardsChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formAchievement">
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                value={achievements.title}
                onChange={handleAchievementsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formAchievement">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                value={achievements.description}
                onChange={handleAchievementsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formAchievement">
              <Form.Label>Requirement</Form.Label>
              <Form.Control
                name="requirement"
                value={achievements.requirement}
                onChange={handleAchievementsChange}
              />
            </Form.Group>
          </Row>
          <Form.Group controlId="formMultiSelect">
            <Form.Label>Select Options</Form.Label>
            <ShuttleList
              className="small"
              available={availableCards}
              selected={selectedCards}
              availableTitle="Available"
              selectedTitle="Selected"
              onSelectionChange={setAiDeck}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAddClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddMission}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddQuestModal;
