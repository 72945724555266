import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { HOST_URL } from "../config/config";

const initialState = {
  totalAIPlayerCount: 0,
  missions: [],
  missionsLoading: false,

  locations: [],
  locationsLoading: false,

  newAiPlayerInfo: {},
  addingNewAiPlayer: false,
};

export const getMissionList = createAsyncThunk(
  "quest/getMissionList",
  async ({ from, limit }) => {
    const result = await axios.get(
      `${HOST_URL}mission?from=${from}&limit=${limit}`
    );
    return result.data;
  }
);

export const addMission = createAsyncThunk(
  "quest/addMission",
  async (newMission) => {
    const result = await axios.post(`${HOST_URL}mission`, newMission);

    return result.data;
  }
);

export const editMission = createAsyncThunk(
  "quest/editMission",
  async (mission) => {
    const result = await axios.put(`${HOST_URL}mission`, mission);

    return result.data;
  }
);

export const removeMission = createAsyncThunk(
  "quest/removeMission",
  async ({ id }) => {
    const result = await axios.delete(`${HOST_URL}mission/${id}`);

    return result.data;
  }
);

export const getLocations = createAsyncThunk("quest/getLocations", async () => {
  const result = await axios.get(`${HOST_URL}location`);

  return result.data.locations;
});

export const addNewLocation = createAsyncThunk(
  "quest/addNewLocation",
  async ({ newLocation }) => {
    const result = await axios.post(`${HOST_URL}location`, newLocation);

    return result.data;
  }
);

export const removeLocation = createAsyncThunk(
  "quest/removeLocation",
  async ({ locationId }) => {
    const result = await axios.delete(`${HOST_URL}location/${locationId}`);

    return result.data;
  }
);

const questSlice = createSlice({
  name: "quest",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMissionList.pending, (state) => {
        state.missionsLoading = true;
      })
      .addCase(getMissionList.fulfilled, (state, { payload }) => {
        state.missions = payload;
        state.missionsLoading = false;
      })
      .addCase(getMissionList.rejected, (state, { error }) => {
        state.missions = [];
        state.missionsLoading = false;
        console.log(error);
      })
      .addCase(addMission.pending, (state) => {})
      .addCase(addMission.fulfilled, (state, { payload }) => {})
      .addCase(addMission.rejected, (state, { error }) => {})

      .addCase(editMission.pending, (state) => {})
      .addCase(editMission.fulfilled, (state, { payload }) => {})
      .addCase(editMission.rejected, (state, { error }) => {})

      .addCase(removeMission.pending, (state) => {})
      .addCase(removeMission.fulfilled, (state, { payload }) => {})
      .addCase(removeMission.rejected, (state, { error }) => {})

      .addCase(addNewLocation.pending, (state) => {})
      .addCase(addNewLocation.fulfilled, (state, { payload }) => {})
      .addCase(addNewLocation.rejected, (state, { error }) => {})

      .addCase(removeLocation.pending, (state) => {})
      .addCase(removeLocation.fulfilled, (state, { payload }) => {})
      .addCase(removeLocation.rejected, (state, { error }) => {})

      .addCase(getLocations.pending, (state) => {
        state.locationsLoading = true;
      })

      .addCase(getLocations.fulfilled, (state, { payload }) => {
        state.locationsLoading = false;
        state.locations = payload;
      })
      .addCase(getLocations.rejected, (state, { error }) => {
        state.locationsLoading = false;
      });
  },
});

export const {} = questSlice.actions;

export default questSlice.reducer;
