export const PAGINATION_CNT = "PAGINATION_CNT";

export const CHART_DATA = "CHART_DATA";

// Sidebar Section
export const SIDEBAR_TAG_DASHBOARD = "SIDEBAR_TAG_DASHBOARD";
export const SIDEBAR_TAG_LEADERBOARD = "SIDEBAR_TAG_LEADERBOARD";
export const SIDEBAR_TAG_PLAYERS = "SIDEBAR_TAG_PLAYERS";
export const SIDEBAR_TAG_DECKS = "SIDEBAR_TAG_DECKS";
export const SIDEBAR_TAG_CARDS = "SIDEBAR_TAG_CARDS";
export const SIDEBAR_TAG_CONFIG = "SIDEBAR_TAG_CONFIG";

// Dashboard Section
export const DASHBOARD_MAIN_DATA = "DASHBOARD_MAIN_DATA";
export const DASHBOARD_CHART_DATA = "DASHBOARD_CHART_DATA";
export const DASHBOARD_ACTIVITY_DATA = "DASHBOARD_ACTIVITY_DATA";

// Decks Section
export const DECKS_ALL_DATA = "DECKS_ALL_DATA";
export const DECKS_DATA = "DECKS_DATA";

// Players Section
export const PLAYER_DEX_ID = "PLAYER_DEX_ID";
